import React, { useContext } from 'react';
import { httpsCallable } from "firebase/functions";
import { AuthContext } from '@fireactjs/core';
import { SubscriptionContext } from '@fireactjs/saas';

const AuthGoogleButton = () => {
    const { subscription } = useContext(SubscriptionContext);
    const { functionsInstance } = useContext(AuthContext);

    const handleSignInClick = () => {
        // call cloud function getGoogleAuthUrl
        const getGoogleAuthUrl = httpsCallable(functionsInstance, 'getGoogleAuthUrl');
        getGoogleAuthUrl({subscriptionId: subscription.id}).then((result) => {
            const { authUrl } = result.data;
            window.location.href = authUrl;
        }).catch((error) => {
            console.error(error);
        });
    };

    return (
        <button onClick={handleSignInClick}>Sign in with Google</button>
    );
};

export default AuthGoogleButton;
