import React, { useEffect, useState, useContext } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { AuthContext } from '@fireactjs/core';
import { SubscriptionContext } from '@fireactjs/saas';
import AuthGoogleButton from '../components/AuthGoogleButton';

const MeetingList = () => {
    const [meetings, setMeetings] = useState([]);
    const { firestoreInstance } = useContext(AuthContext);
    const { subscription } = useContext(SubscriptionContext);

    useEffect(() => {

        // Get the Firestore collection reference
        const meetingsRef = collection(firestoreInstance, 'subscriptions/'+subscription.id+'/meetings');

        // get documents
        getDocs(meetingsRef).then((snapshot) => {
            const meetingsData = [];
            snapshot.forEach((doc) => {
                // Get the data of each meeting document
                const meetingData = doc.data();
                meetingsData.push(meetingData);
            });
            setMeetings(meetingsData);
        });
    }, [subscription.id, firestoreInstance]);

    return (
        <div>
            <h1>Meeting List</h1>
            <AuthGoogleButton />
            <ul>
                {meetings.map((meeting) => (
                    <li key={meeting.id}>{meeting.title}</li>
                ))}
            </ul>
        </div>
    );
};

export default MeetingList;
