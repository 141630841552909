import React, { useEffect, useContext } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { AuthContext } from '@fireactjs/core';
import { httpsCallable } from "firebase/functions";

const GoogleTokenSuccess = () => {

    const { firestoreInstance, functionsInstance, authInstance } = useContext(AuthContext);

    useEffect(() => {
        // get the token from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        // get the state from the URL
        const subscriptionId = urlParams.get('state');
        // check if the subscription ID is valid in firestore
        const subRef = doc(firestoreInstance, 'subscriptions/'+subscriptionId);
        getDoc(subRef).then((doc) => {
            if (doc.exists() && doc.data().permissions['access'].indexOf(authInstance.currentUser.uid) !== -1) {
                // call exchangeGoogleAuthCode cloud function
                const exchangeGoogleAuthCode = httpsCallable(functionsInstance, 'exchangeGoogleAuthCode');
                exchangeGoogleAuthCode({code: code, subscriptionId: subscriptionId}).then((result) => {
                    console.log(result.data);
                }).catch((error) => {
                    console.error(error);
                });                
            } else {
                console.error("Invalid subscription ID");
            }
        }).catch((error) => {
            console.error("Error getting document:", error);
        });

    },[authInstance.currentUser.uid, firestoreInstance, functionsInstance]);

    return (
        <div>
            
        </div>
    );
};

export default GoogleTokenSuccess;
